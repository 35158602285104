.dzuSQq_personalDataForm {
  --primary-color: #075da7;
  --primary-border-color: #075da7;
  --primary-text-color: #fff;
  --primary-hover-color: #193f99;
  --primary-hover-border-color: #193f99;
  --primary-hover-text-color: #fff;
  --secondary-color: #f70d1c;
  --secondary-border-color: #f70d1c;
  --secondary-text-color: #fff;
  --secondary-hover-color: #df0715;
  --secondary-hover-border-color: #df0715;
  --secondary-hover-text-color: #fff;
  background-color: var(--primary-text-color);
  opacity: 1;
  border-radius: 8px;
  padding: 32px;
  font-size: 16px;
  transition: all .2s linear;
}

.dzuSQq_action {
  color: var(--form-text-color);
  flex: 50%;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  position: relative;
}

div.dzuSQq_customPhoneInput > div > i {
  color: var(--form-arrow-color) !important;
  font-size: 16px !important;
}

.dzuSQq_disclaimerButton {
  cursor: pointer;
  color: var(--secondary-color-palette);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-decoration: underline;
  display: inline;
}

.dzuSQq_disclaimer {
  font-size: 14px;
  line-height: 1.33;
}

.dzuSQq_disclaimerRow {
  flex-direction: row;
}

.dzuSQq_disclaimer i {
  cursor: pointer;
  margin-left: .5rem;
  padding-top: 1rem;
}

.dzuSQq_disclaimerCheckBox {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-right: -6px;
}

.dzuSQq_expContent {
  text-align: justify;
  padding-top: 10px;
  font-size: 12px;
  line-height: 1.33;
}

.dzuSQq_btnHolder {
  text-align: center;
  margin-top: 12px;
}

div.dzuSQq_customFieldRow {
  flex-direction: column;
  margin: 0 -6px;
  display: flex;
}

@media screen and (min-width: 993px) {
  .dzuSQq_action {
    display: block;
  }
}

@media screen and (min-width: 1280px) {
  .dzuSQq_action {
    flex: 1 0 600px;
  }

  div.dzuSQq_customFieldRow {
    flex-flow: wrap;
  }
}

* {
  --form-text-color: #080a0c;
  --form-text-error-color: #e21a1e;
  --form-text-title-color: #154dd1;
  --form-text-success-color: #193f99;
  --form-text-info-color: #323a48;
  --form-arrow-color: #747474;
  --form-background-info-color: #f7f9ff;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

p.captcha-info, p.captcha-info a {
  color: #81909d;
  text-align: center;
  margin-top: 13px !important;
  font-size: 14px !important;
}

p.captcha-info a {
  text-decoration: underline;
}

/*# sourceMappingURL=index.css.map */
